export const DISCOUNT: Record<
  string,
  {
    discount: number;
    footnote?: string;
    apartmentSlugs?: Record<
      string,
      {
        discount: number;
        footnote?: string;
      }
    >;
    apartmentTypes?: Record<
      string,
      {
        discount: number;
        footnote?: string;
      }
    >;
  }
> = {
  hamburg: {
    discount: 0,
    footnote: "discount-footnote",
    apartmentSlugs: undefined,
    apartmentTypes: {
      coliving: {
        discount: 20,
        footnote: "discount-footnote",
      },
    },
  },
  paris: {
    discount: 15,
    apartmentSlugs: undefined,
    apartmentTypes: undefined,
    footnote: "discount-footnote",
  },
  berlin: {
    discount: 0,
    footnote: "discount-footnote",
    apartmentSlugs: {
      "rudolf-15": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "rudolf-20": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "rudolf-27": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "rudolf-33": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "rudolf-34": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "rudolf-30": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "arnold-1": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-2": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-3": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-4": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-5": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-6": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-7": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-8": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-9": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-10": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-11": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-12": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-13": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-14": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-15": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-16": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-17": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-18": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-19": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-20": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-21": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-22": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-23": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-24": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "arnold-25": {
        discount: 50,
        footnote: "discount-footnote-arnold",
      },
      "flora-43": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
      "flora-34": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
      "flora-06": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
      "flora-05": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
      "flora-16": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
      "flora-25": {
        discount: 50,
        footnote: "discount-footnote-flora",
      },
    },
    apartmentTypes: undefined,
  },
  oslo: {
    discount: 0,
    footnote: "discount-footnote",
    apartmentSlugs: {
      "edvard-1": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-2": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-3": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-4": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-5": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-6": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-7": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-8": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-9": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-10": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-11": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-12": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-13": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-14": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-15": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-16": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-17": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-18": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-19": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-20": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-21": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-22": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-23": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-24": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-25": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-26": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-27": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-28": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-29": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-30": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-31": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-32": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-33": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-34": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-35": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-36": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-37": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-38": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-39": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-40": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-41": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-42": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-43": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-44": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-45": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-46": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-47": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-48": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-49": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-50": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-51": {
        discount: 15,
        footnote: "discount-footnote",
      },
      "edvard-52": {
        discount: 15,
        footnote: "discount-footnote",
      },
    },
    apartmentTypes: undefined,
  },
  copenhagen: {
    discount: 15,
    footnote: "discount-footnote-copenhagen",
    apartmentTypes: undefined,
  },
};

export const DEPOSIT: Record<string, { shared: number; private: number }> = {
  aarhus: {
    private: 10000,
    shared: 10000,
  },
  copenhagen: {
    private: 10000,
    shared: 10000,
  },
  oslo: {
    private: 0,
    shared: 0,
  },
  berlin: {
    private: 1000,
    shared: 1000,
  },
  halle: {
    private: 1000,
    shared: 1000,
  },
  hamburg: {
    private: 1800,
    shared: 1000,
  },
  munich: {
    private: 1000,
    shared: 1000,
  },
  paris: {
    private: 1000,
    shared: 1000,
  },
  london: {
    private: 1300,
    shared: 1300,
  },
};
